import { Theme } from '@mui/material'

import { palette } from './palette'
import { typography } from './typography'

/* MUI Reference https://mui.com/customization/theme-components/#main-content */
export const components: Theme['components'] = {
    MuiAccordion: {
        styleOverrides: {
            root: {
                width: '100%',
                '&:before': {
                    display: 'none',
                },
                '& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded': {
                    cursor: 'default',
                },
                '& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-focusVisible': {
                    backgroundColor: 'transparent',
                },
            },
        },
    },

    MuiAccordionDetails: {
        styleOverrides: {
            root: {
                paddingTop: 16,
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
    },

    MuiAccordionSummary: {
        styleOverrides: {
            root: {
                maxHeight: '48px',
                minHeight: '48px',
                flexDirection: 'row-reverse',
                '&.Mui-expanded': {
                    maxHeight: '48px',
                    minHeight: '48px',
                },
                paddingLeft: 0,
                paddingRight: 0,
                borderBottom: `1px solid ${palette.onColouredSurface.hover}`,
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                    transform: 'rotate(180deg)',
                },
            },
            expandIconWrapper: {
                marginRight: 8,
            },
        },
    },

    MuiAlert: {
        styleOverrides: {
            standardSuccess: {
                color: palette.success.main,
                backgroundColor: palette.success.light,
                fontSize: 14,
                '& .MuiAlert-icon': {
                    display: 'flex',
                    alignItems: 'center',
                },
            },
            standardError: {
                color: palette.error.main,
                backgroundColor: palette.error.light,
            },
            standardWarning: {
                color: palette.warning.main,
                backgroundColor: palette.warning.light,
            },
            standardInfo: {
                color: palette.information.main,
                backgroundColor: palette.information.light,
            },
        },
    },

    MuiAppBar: {
        styleOverrides: {
            colorPrimary: {
                backgroundColor: palette.light.white,
            },
        },
    },

    MuiAutocomplete: {
        styleOverrides: {
            root: {
                width: '378px',
            },
            listbox: {
                fontSize: 14,
            },
            option: {
                '&.Mui-selected': {
                    backgroundColor: palette.onColouredSurface.hover,
                    '&.Mui-focusVisible': { backgroundColor: palette.onColouredSurface.hover },
                },
                '&.Mui-selected:hover': {
                    backgroundColor: palette.onColouredSurface.hover,
                },
            },
            noOptions: {
                fontSize: 14,
            },
        },
    },

    MuiAvatar: {
        styleOverrides: {
            root: {
                width: 28,
                height: 28,
            },
        },
    },

    MuiButton: {
        styleOverrides: {
            root: {
                textTransform: 'none',
            },
            endIcon: {
                marginRight: 0,
                '& > *:nth-of-type(1)': {
                    fontSize: 'inherit',
                },
            },
            iconSizeMedium: {
                '& > *:first-child': {
                    fontSize: 24,
                },
            },
        },
        variants: [
            {
                props: { variant: 'outlined' },
                style: {
                    color: palette.secondary.main,
                    borderColor: palette.secondary.main,
                    borderRadius: 2,
                },
            },
            {
                props: { variant: 'contained' },
                style: {
                    color: palette.light.white,
                    backgroundColor: palette.primary.main,
                    borderRadius: 2,
                },
            },
        ],
    },

    MuiCheckbox: {
        defaultProps: {
            color: 'secondary',
        },
    },

    MuiCssBaseline: {
        styleOverrides: `
      body {
        background-color: #fcfcfb;
      }
    `,
    },

    MuiDivider: {
        styleOverrides: {
            root: {
                borderColor: palette.onColouredSurface.hover,
            },
        },
    },

    MuiDrawer: {
        styleOverrides: {
            root: {
                '&.MuiDrawer-paper': {
                    padding: '18px',
                },
            },
        },
    },

    MuiFormControlLabel: {
        styleOverrides: {
            label: {
                fontSize: 14,
                color: palette.dark.simple,
            },
        },
    },

    MuiFormControl: {
        styleOverrides: {
            root: {
                width: '100%',
            },
        },
    },

    MuiFormHelperText: {
        styleOverrides: {
            root: {
                fontSize: 12,
                marginLeft: 0,
            },
        },
    },

    MuiFormLabel: {
        styleOverrides: {
            root: {
                fontSize: 16,
                color: palette.dark.simple,
                '&.Mui-focused': {
                    color: palette.dark.simple,
                },
            },
        },
    },

    MuiInputLabel: {
        styleOverrides: {
            root: {
                fontSize: 16,
                color: palette.dark.lighter,
                '&.Mui-focused': {
                    fontSize: 12,
                    color: palette.dark.lighter,
                },
                '&.MuiFormLabel-filled': {
                    fontSize: 12,
                    color: palette.dark.lighter,
                },
                '&.MuiInputLabel-shrink': {
                    fontSize: 12,
                    color: palette.dark.lighter,
                },
            },
        },
    },

    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                '& fieldset': {
                    fontSize: 12,
                },
                color: palette.dark.simple,
            },
        },
    },

    MuiMenu: {
        styleOverrides: {
            paper: {
                maxHeight: '400px',
            },
        },
    },

    MuiMenuItem: {
        styleOverrides: {
            root: {
                fontSize: 14,
                '@media (min-width: 600px)': {
                    minHeight: '33px',
                },
                justifyContent: 'space-between',
                gap: 6,
                '&.Mui-selected': {
                    backgroundColor: palette.onColouredSurface.hover,
                    '&.Mui-focusVisible': { backgroundColor: palette.onColouredSurface.hover },
                },
                '&.Mui-selected:hover': {
                    backgroundColor: palette.onColouredSurface.hover,
                },
            },
        },
    },

    MuiSelect: {
        styleOverrides: {
            root: {
                fontSize: 16,
                caretColor: palette.primary.main,
                fieldset: {
                    borderColor: palette.dark.lighter,
                },
                '&:hover fieldset': {
                    borderColor: palette.dark.lighter,
                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.4)',
                },
                '&.Mui-focused fieldset': {
                    borderColor: palette.dark.lighter,
                    borderWidth: '1px',
                },
            },
        },
    },

    MuiRadio: {
        defaultProps: {
            color: 'secondary',
        },
        styleOverrides: {
            root: {
                padding: 4,
                '&.MuiFormControlLabel': {
                    color: 'blue',
                },
            },
        },
    },

    MuiTabs: {
        styleOverrides: {
            indicator: {
                backgroundColor: palette.brand.main,
            },
        },
    },

    MuiTab: {
        styleOverrides: {
            root: {
                color: palette.secondary.main,
                textTransform: 'none',
            },
        },
    },

    MuiTable: {
        styleOverrides: {
            root: {
                height: 200,
            },
        },
    },

    MuiTableCell: {
        styleOverrides: {
            root: {
                borderBottom: `1px solid ${palette.onColouredSurface.hover}`,
            },
        },
    },

    MuiTableContainer: {
        styleOverrides: {
            root: {
                border: `1px solid ${palette.onColouredSurface.hover}`,
                borderBottom: 0,
                borderRadius: '4px 4px 0px 0px',
            },
        },
    },

    MuiTableHead: {
        styleOverrides: {
            root: {
                textTransform: 'uppercase',
            },
        },
    },

    MuiTablePagination: {
        styleOverrides: {
            displayedRows: {
                color: palette.dark.lighter,
                fontSize: typography.body4.fontSize,
                marginLeft: 'auto',
            },
            toolbar: {
                color: palette.dark.lighter,
                fontSize: typography.body4.fontSize,
            },
            selectLabel: {
                fontSize: typography.body4.fontSize,
            },
            actions: {
                color: palette.dark.simple,
            },
            spacer: { maxWidth: 0 },
        },
    },

    MuiTableRow: {
        styleOverrides: {
            root: {
                '&.MuiTableRow-hover:hover': {
                    backgroundColor: palette.light.white,
                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.4)',
                    cursor: 'pointer',
                    position: 'relative',
                },
                '&:nth-of-type(even)': {
                    backgroundColor: palette.light.lightShade40,
                },
                '&:nth-of-type(odd)': {
                    backgroundColor: palette.light.white,
                },
            },
        },
    },

    MuiTextField: {
        styleOverrides: {
            root: {
                '& input, & textarea': {
                    fontSize: 16,
                },
            },
        },
        variants: [
            {
                props: { variant: 'outlined' },
                style: {
                    '.MuiOutlinedInput-root': {
                        fieldset: {
                            borderColor: palette.dark.lighter,
                        },
                        '&:hover fieldset': {
                            borderColor: palette.dark.lighter,
                            boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.4)',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: palette.dark.lighter,
                            borderWidth: '1px',
                        },
                    },
                },
            },
        ],
    },

    MuiTypography: {
        styleOverrides: {
            root: {
                color: palette.dark.lighter,
            },
        },
    },
}
